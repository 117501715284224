import { ArrowRightIcon, DoubleRightIcon } from 'components/icons';

import Image from "next/future/image"
import Link from 'next/link'
import MarkdownText from "components/atoms/markdown"
import React from 'react'

function GridItem({ item, colourClass }) {
    const { heading, body, linkTo, linkText } = item;

     return (
        <React.Fragment>
            <div className="relative aspect-w-1 aspect-h-1">
                <div className="w-full h-full">
                    {item.image?.data?.attributes?.url && (
                        <Image 
  src={item.image.data.attributes.url}
  width={item.image.data.attributes.width}
  height={item.image.data.attributes.height}
  alt={item.image.data.attributes.alternativeText}
  className="object-cover object-center w-full h-full"
  unoptimized
/>
                    )}
                </div>
                <div className={`absolute top-0 bottom-0 left-0 right-0 ${colourClass} opacity-70 z-10`} />
            </div>
            <div className={`sm:aspect-w-1 sm:aspect-h-1 ${colourClass}`}>
                <div className="p-6 flex flex-col gap-6">
                <h3 className="font-bold text-2xl">{heading}</h3>
                <MarkdownText>{body}</MarkdownText>
                {linkTo && (
                    <Link href={linkTo} passHref>
                        <a className="flex flex-row items-center gap-2">
                            <span>{linkText}</span>
                            <span><ArrowRightIcon className="w-2 h-2" /></span>
                        </a>
                    </Link>
                )}
                </div>
            </div>
        </React.Fragment>
    );
}

const COLOUR_CLASSES = ["bg-blue-100", "bg-teal-500", "bg-green-500", "bg-pink-500"];

export default function InfoGrid({ heading, items, ctaLinkText, ctaLinkTo }) {
    return (
        <section className="flex flex-col gap-8 max-w-7xl mx-auto pt-10 pb-0 xl:pb-10 xl:mb-10">
            <h2 className="text-2xl font-normal text-center text-pink-500">{heading}</h2>
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 grid-flow-row sm:auto-rows-fr">
                {(items||[]).map((item, idx) => (
                    <GridItem item={item} key={idx} colourClass={COLOUR_CLASSES[idx % COLOUR_CLASSES.length]} />
                ))}
                <div className="bg-blue-500 flex flex-col justify-center items-center lg:items-start p-6 sm:col-span-2 lg:col-span-1">
                    <Link href={ctaLinkTo} passHref>
                        <a className="flex-1 flex flex-row items-center gap-2 text-white text-3xl font-bold">
                            <span>{ctaLinkText}</span>
                            <span><DoubleRightIcon className="w-6 h-6" /></span>
                        </a>
                    </Link>
                </div>
            </div>
        </section>
    );
}
